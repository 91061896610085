import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import CustomizedTable from './my-requests-details-table';
import { FileDto, ReportType } from 'lib/api/file.protocols';
import { FileApi } from 'lib/api/file';
import { Box, Button, Container, Modal, Stack } from '@mui/material';
import { OpenViewDetailsPropTypes } from '../../types';
import dayjs from 'dayjs';
import { BaseBreadcrumbs, FakeButton } from './style';
import { useSnackbar } from 'notistack';

interface IElements {
  id?: string;
  name: string;
}

const tableHead = ['Nome', 'Descrição', 'Nome', 'Descrição'];
function formatMilliseconds(ms: number) {
  const minutes = Math.floor(ms / 60000);
  const seconds = Math.floor((ms % 60000) / 1000);

  return `${minutes}’${seconds.toString().padStart(2, '0')}’’`;
}

const MyRequestDetailsPage = ({
  goToAllRequests,
  data,
}: {
  goToAllRequests: () => void;
  data: OpenViewDetailsPropTypes;
}) => {
  const [reportFiles, setReportFiles] = useState<FileDto[]>([]);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const {
    assessmentResponse,
    candidate,
    company,
    instrument,
    region,
    costCenter,
    status,
    analyst,
    id,
    assessmentInfo,
    requestManagement,
    requestOrigin,
    jobTitle,
    additionalNote,
    requestDate,
    sector,
  } = data;

  const handleOpenModal = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const SelfieModal = () => (
    <Modal
      open={isModalOpen}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Sua selfie
        </Typography>
        <Box
          component="img"
          src={candidate.selfie}
          alt="Selfie"
          sx={{
            width: '100%',
            height: 'auto',
            display: 'flex',
          }}
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: '1rem',
          }}
        >
          <Button onClick={handleCloseModal} sx={{ mt: 2 }}>
            Fechar
          </Button>
          <Button sx={{ mt: 2 }} variant="contained">
            <a
              href={candidate.selfie}
              download
              style={{
                textDecoration: 'none',
                color: 'white',
              }}
            >
              Download Selfie
            </a>
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  const getReportFiles = async () => {
    try {
      const reportFiles = await FileApi.searchByRequestId(id);
      setReportFiles(reportFiles);
    } catch (e) {
      console.error('Erro ao buscar arquivo', e);
    }
  };

  useEffect(() => {
    getReportFiles();
  }, []);

  const fakeButtonBgColor = (variant: string | undefined) => {
    switch (variant) {
      case 'em andamento':
        return '#f9f158';
      case 'cancelado':
        return '#ed4c40';
      case 'correção':
        return '#f1a262';
      case 'congelado':
        return '#cecece';
      case 'finalizado':
        return '#81c163';
      case 'reanálise':
        return '#6495ED';
    }
  };

  const getReportUrl = async (
    azureFileName: string,
    reportUser: IElements | undefined,
  ) => {
    try {
      const reportType = reportUser
        ? ReportType.CONSULTANT_REPORT
        : ReportType.ASSESSMENT_REPORT;

      const url = await FileApi.getReportUrl({ azureFileName, reportType });
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', url);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.error(e);
      enqueueSnackbar('Erro ao gerar url do arquivo', { variant: 'error' });
    } finally {
    }
  };

  const tableBodyWithoutAssessment = [
    {
      label1: 'Solicitante:',
      value1: analyst.name,
      label2: 'solicitação:',
      value2: dayjs(requestDate).format('DD/MM/YYYY'),
    },
    {
      label1: 'Empresa:',
      value1: company.name,
      label2: 'Data de envio dos links:',
      value2: requestManagement.linkSentDate
        ? dayjs(requestManagement.linkSentDate).format('DD/MM/YYYY')
        : '',
    },
    {
      label1: 'E-mail do solicitante:',
      value1: analyst.email ? analyst.email : '',
      label2: 'Data de envio ao cliente:',
      value2: requestManagement.resultSentToClientDate
        ? dayjs(requestManagement.resultSentToClientDate).format('DD/MM/YYYY')
        : '',
    },
    {
      label1: 'Instrumento a ser enviado:',
      value1: instrument.name,
      label2: 'Centro de custo:',
      value2: costCenter,
    },
    {
      label1: 'Nome completo:',
      value1: candidate.name,
      label2: 'Unidade organizacional:',
      value2: sector.name,
    },
    {
      label1: 'CPF:',
      value1: candidate.cpf,
      label2: 'Observações:',
      value2: additionalNote,
    },
    {
      label1: 'E-mail do avaliado:',
      value1: candidate.email,
      label2: 'Status:',
      value2: (
        <FakeButton variant={fakeButtonBgColor(status.toLowerCase())}>
          {status}
        </FakeButton>
      ),
    },
    {
      label1: 'Whatsapp:',
      value1: candidate.whatsapp,
      label2: 'Arquivos:',
      value2: reportFiles ? (
        <React.Fragment>
          {reportFiles.map((file, index) => {
            return (
              <a
                key={index}
                style={{
                  textDecoration: 'none',
                  color: '#1976D2',
                  cursor: 'pointer',
                }}
                onClick={() => getReportUrl(file.azureFileName, file.user)}
              >{`arquivo ${index + 1}`}</a>
            );
          })}
        </React.Fragment>
      ) : (
        ''
      ),
    },
    {
      label1: 'Cargo:',
      value1: jobTitle.name,
      label2: 'Resultado:',
      value2: requestManagement.result
        ? requestManagement.result.toLowerCase()
        : '',
    },
    {
      label1: 'Origem da solicitação:',
      value1: requestOrigin,
      label2: 'Observação do(a) consultor(a) MS:',
      value2: requestManagement.consultantNote,
    },
  ];

  const tableBodyWithAssessment = [
    {
      label1: 'Nome Completo',
      value1: candidate.name,
      label2: 'Solicitante',
      value2: analyst.name,
    },
    {
      label1: 'CPF',
      value1: candidate.cpf,
      label2: 'Email do solicitante',
      value2: analyst.email,
    },
    {
      label1: 'Email',
      value1: candidate.email,
      label2: 'Empresa',
      value2: company.name,
    },
    {
      label1: 'Telefone',
      value1: candidate.whatsapp,
      label2: 'Centro de custo',
      value2: costCenter,
    },
    {
      label1: 'Idade',
      value1: candidate.age,
      label2: 'Observações',
      value2: additionalNote,
    },
    {
      label1: 'Gênero',
      value1: candidate.gender,
      label2: 'Nota MS',
      value2: assessmentResponse?.msScore,
    },
    {
      label1: 'Região',
      value1: region,
      label2: 'Classificação',
      value2: assessmentResponse?.classification && (
        <Box
          sx={{
            backgroundColor: '#DBDBDB',
            padding: '6px 12px',
            borderRadius: '4px',
            textTransform: 'capitalize',
            width: '90%',
            textAlign: 'center',
          }}
        >
          {assessmentResponse?.classification.toLowerCase()}
        </Box>
      ),
    },
    {
      label1: 'Foto',
      value1: (
        <Typography
          style={{
            color: '#1976D2',
            textDecoration: 'none',
            cursor: 'pointer',
          }}
          onClick={handleOpenModal}
        >
          ver arquivo
        </Typography>
      ),
      label2: 'Data de conclusão',
      value2: assessmentResponse?.finishedAt
        ? dayjs(assessmentResponse?.finishedAt).format('DD/MM/YYYY')
        : '',
    },
    {
      label1: 'Status',
      value1: (
        <FakeButton variant={fakeButtonBgColor(status.toLowerCase())}>
          {status}
        </FakeButton>
      ),
      label2: 'Arquivo',
      value2: reportFiles?.[0]?.fileName ? (
        <a
          style={{
            color: '#1976D2',
            textDecoration: 'none',
            cursor: 'pointer',
          }}
          onClick={() =>
            getReportUrl(reportFiles[0].azureFileName, reportFiles[0].user)
          }
          rel="noopener noreferrer"
        >
          Relatório
        </a>
      ) : (
        ''
      ),
    },
    {
      label1: 'Instrumento',
      value1: instrument.name,
      label2: '',
      value2: '',
    },
  ];

  const testInfo = [
    {
      label: 'Nota MS',
      value: assessmentResponse?.msScore,
    },
    {
      label: 'Percentil',
      value: assessmentResponse?.percentile,
    },
    {
      label: 'Tempo',
      value: assessmentResponse?.executionTime
        ? formatMilliseconds(assessmentResponse.executionTime)
        : '',
    },
    {
      label: 'Acertos / Erros / Omissões',
      value:
        assessmentResponse?.hits &&
        `${assessmentResponse?.hits} / ${assessmentResponse?.errors} / ${assessmentResponse?.omissions}`,
    },
  ];

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
      }}
    >
      <BaseBreadcrumbs aria-label="breadcrumb">
        <Link onClick={goToAllRequests}>minhas solicitações</Link>
        <Typography color="text.primary">detalhes da solicitação</Typography>
      </BaseBreadcrumbs>

      <Typography variant="h3" color="#66717f">
        Detalhes da solicitação
      </Typography>
      {assessmentInfo && assessmentInfo.assessmentBaseId ? (
        <>
          <CustomizedTable
            tableHead={tableHead}
            tableBody={tableBodyWithAssessment}
          />
          <SelfieModal />
          <Stack
            style={{
              gap: '1rem',
            }}
          >
            <Typography>PROVA</Typography>
            <Box
              sx={{
                border: '1px solid var(--BACKOFFICE-Light-Gray, #DBDBDB)',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  borderBottom:
                    '1px solid var(--BACKOFFICE-Light-Gray, #DBDBDB)',
                  background: '#D1E8FF',
                  fontSize: '16px',
                  color: 'var(--BACKOFFICE-Preto, #000)',
                  padding: '10px 16px',
                }}
              >
                Atenção e concentração
              </Box>
              <Box
                sx={{
                  gap: '0.5rem',
                  padding: '6px 16px 10px 16px',
                }}
              >
                {testInfo.map((item, index) => {
                  return (
                    <Typography
                      key={index}
                      sx={{
                        display: 'flex',
                        gap: '0.5rem',
                        color: 'var(--BACKOFFICE-Cinza, #66717F)',
                        fontSize: '16px',
                      }}
                    >
                      {item.label}
                      <Typography
                        sx={{
                          color: 'var(--BACKOFFICE-Preto, #000)',
                          fontSize: '16px',
                        }}
                      >
                        {item.value}
                      </Typography>
                    </Typography>
                  );
                })}
              </Box>
            </Box>
          </Stack>
        </>
      ) : (
        <CustomizedTable
          tableHead={tableHead}
          tableBody={tableBodyWithoutAssessment}
        />
      )}
    </Container>
  );
};

export { MyRequestDetailsPage };
