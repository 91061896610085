import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from '@mui/material';
import dayjs from "dayjs";

import {BaseCardBox, BaseDialog, EditButton, Header, LoadingContainer} from "./styles";
import {CreateUniversity, UniversityEntity, UpdateUniversity} from "../../../lib/api/university.protocol";
import {RequestApi} from "../../../lib/api/requests";
import {ISnackBarSettings} from "../../../lib/helpers/snackbar-interface";
import CustomizedSnackbars from "../../../lib/components/SnackBar";
import {UniversityApi} from "../../../lib/api/university";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteButton from "../../../lib/components/DeleteButton";
import CustomizedTable from "../components/Table";
import {BaseTextField} from "../../instruments/pages/list/style";

export interface ITableBodyCreateUniversity {
    name: string;
    typeOfContent: string;
    dateOfPublish: string;
    dateOfUpdate: string;
    actions: JSX.Element;
}

export const CreateUniversityMS = () => {
    const [universityContent, setUniversityContent] = useState<UniversityEntity[]>([]);
    const [universityAllContent, setUniversityAllContent] = useState<UniversityEntity[]>([]);

    const [contentName, setContentName] = useState<string>('');
    const [contentType, setContentType] = useState<string>('material_de_apoio');
    const [contentSummary, setContentSummary] = useState<string>('');
    const [contentLink, setContentLink] = useState<string>('');
    const [contentId, setContentId] = useState<string>('');

    const [loading, setLoading] = useState<boolean>(false);

    // Função para calcular a data de ontem
    const getYesterdayDate = () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate()); // Subtrai um dia
        return yesterday.toISOString().split('T')[0]; // Formato YYYY-MM-DD
    };

    const [publishDate, setPublishDate] = useState<string>(getYesterdayDate);

    // URL do preview da imagem
    const [selectedImagePreviewUrl, setSelectedImagePreviewUrl] = useState<string>('');

    // arquivo da imagem para subir
    const [selectedImageBlob, setSelectedImageBlob] = useState<any>('');

    // URL do preview do conteudo
    const [selectedContentPreviewUrl, setSelectedContentPreviewUrl] = useState<string>('');

    // arquivo do conteudo para subir
    const [selectedContentBlob, setSelectedContentBlob] = useState<any>('');

    // URL da imagem que subiu no cluster do azure
    const [selectedImageFileUrl, setSelectedImageFileUrl] = useState<string>('');

    // URL do arquivo que subiu no cluster do azure
    const [selectedContentFileUrl, setSelectedContentFileUrl] = useState<string>('');

    const [snackBarSettings, setSnackBarSettings] = useState<ISnackBarSettings>({
        show: false,
    });

    const [open, setOpen] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [tableBody, setTableBody] = useState<ITableBodyCreateUniversity[]>([]);
    const tableHead = ['Nome', 'Tipo Conteúdo', 'Data de Publicação', 'Data de Atualização', 'Ações'];

    const navigate = useNavigate();

    // Handle Save operation
    const handleSave = async () => {
        setLoading(true);

        // Validações
        const validations = [
            {condition: !contentName, message: 'Preencha o nome do conteúdo!'},
            {condition: !contentType, message: 'Preencha o tipo do conteúdo!'},
            {
                condition: !contentLink && contentType === 'treinamentos_e_certificacoes',
                message: 'Preencha o link do conteúdo de treinamento/certificação!',
            },
            {condition: !publishDate, message: 'Preencha a data de publicação do conteúdo!'},
            {condition: !contentSummary, message: 'Preencha o resumo do conteúdo!'},
            {condition: contentSummary.length > 65, message: 'Limite máximo de 65 caracteres para o resumo!'},
            {
                condition: !selectedContentBlob && contentType !== 'treinamentos_e_certificacoes',
                message: 'Insira um conteúdo (arquivo)!',
            },
            {condition: !selectedImageBlob, message: 'Insira uma imagem para o conteúdo!'},
        ];

        for (const validation of validations) {
            if (validation.condition) {
                resetSnackBar();
                setSnackBarSettings({
                    show: true,
                    message: validation.message,
                    severity: 'warning',
                });
                setLoading(false);
                return;
            }
        }

        try {
            if (contentType === 'treinamentos_e_certificacoes') {
                // Subir imagem
                const [imageUrl] = await Promise.all([
                    uploadFiles(selectedImageBlob),
                ]);

                setSelectedImageFileUrl(imageUrl);

                // Submeter o formulário
                await submitForm('', imageUrl);
            } else {
                // Subir arquivo e imagem
                const [fileUrl, imageUrl] = await Promise.all([
                    uploadFiles(selectedContentBlob),
                    uploadFiles(selectedImageBlob),
                ]);

                setSelectedContentFileUrl(fileUrl);
                setSelectedImageFileUrl(imageUrl);

                // Submeter o formulário
                await submitForm(fileUrl, imageUrl);
            }
        } catch (error) {
            handleApiError(error);
        } finally {
            setLoading(false);
            resetFormFields();
            setOpen(false);
        }
    };

    const uploadFiles = async (e: any): Promise<any> => {
        if (!e) return Promise.resolve(''); // Se não existir arquivo, retorna vazio.

        const formData = new FormData();

        try {
            // Verificar se existem arquivos
            const files = e?.target?.files;
            if (files?.length) {
                Array.from(files).forEach((file: any) => formData.append('files', file));

                // Solicitar links do arquivo
                const response = await RequestApi.retrieveFilesLinks(formData);
                console.log(response[0]?.fileUrl)
                return Promise.resolve(response[0]?.fileUrl || '');
            }
        } catch (error) {
            handleApiError(error);
        }
    };

    const submitForm = async (fileUrl: string, imageUrl: string) => {
        try {
            if (isEdit) {
                // Corpo da requisição
                const body: UpdateUniversity = {
                    name: contentName,
                    type_of_content: contentType,
                    resume: contentSummary,
                    link: fileUrl && fileUrl.length > 0 && contentType !== 'treinamentos_e_certificacoes' ? fileUrl : contentLink,
                    fileUrlImage: imageUrl && imageUrl.length > 0 ? imageUrl : selectedImageFileUrl,
                };

                UniversityApi.editUniversity(contentId, body)
                    .then(() => {
                        showSuccessSnackBar('Sucesso ao editar conteúdo da universidade MS');
                        // buscar todos os conteudos novamente
                        getUniversityAllContent();
                    })
                    .catch((e) => {
                        handleApiError(e);
                    });
            } else {
                // Corpo da requisição
                const body: CreateUniversity = {
                    name: contentName,
                    type_of_content: contentType,
                    resume: contentSummary,
                    date_of_publish: publishDate,
                    link: fileUrl && fileUrl.length > 0 && contentType !== 'treinamentos_e_certificacoes' ? fileUrl : contentLink,
                    fileUrlImage: imageUrl && imageUrl.length > 0 ? imageUrl : selectedImageFileUrl,
                };

                UniversityApi.createUniversity(body)
                    .then(() => {
                        showSuccessSnackBar('Sucesso ao criar conteúdo da universidade MS');
                        // buscar todos os conteudos novamente
                        getUniversityAllContent();
                    })
                    .catch((e) => {
                        handleApiError(e);
                    });
            }
        } catch (error) {
            handleApiError(error);
        }
    };

    // Função para resetar campos do formulário
    const resetFormFields = () => {
        setContentId('');

        setContentName('');
        setContentType('material_de_apoio');
        setContentSummary('');
        setContentLink('');
        setPublishDate(getYesterdayDate);

        setSelectedImagePreviewUrl('');
        setSelectedImageBlob(null);
        setSelectedImageFileUrl('');

        setSelectedContentPreviewUrl('');
        setSelectedContentBlob(null);
        setSelectedContentFileUrl('');
    };

    // Função para exibir sucesso
    const showSuccessSnackBar = (message: string) => {
        setSnackBarSettings({
            show: true,
            message,
            severity: 'success',
        });
    };

    // Função para tratamento de erros
    const handleApiError = (err: any) => {
        console.log(err);
        let message = 'Ocorreu um erro inesperado.';

        if (err.code === 'ERR_BAD_REQUEST') {
            message = 'Verifique as informações fornecidas e tente novamente!';
        } else if (err.code === 'ERR_NETWORK') {
            message = 'Servidor instável, aguarde alguns segundos e recarregue a página por favor';
        } else if (err.message === 'Network Error') {
            message = 'A imagem deve ter no máximo 3MB';
        } else if (err.message) {
            message = err.message;
        }

        setLoading(false);

        setSnackBarSettings({
            show: true,
            message,
            severity: 'error',
        });
        resetSnackBar();
    };

    const resetSnackBar = () => {
        setTimeout(() => {
            setSnackBarSettings({show: false});
        }, 3000);
    };

    // Handle image file change
    const handleImageChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImagePreviewUrl(URL.createObjectURL(file));
            setSelectedImageBlob(event);
        }
    };

    const handleContentChange = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedContentPreviewUrl(URL.createObjectURL(file));
            setSelectedContentBlob(event);
        }
    };

    const getTypeOfContentText = (item: string) => {
        switch (item) {
            case 'material_de_apoio':
                return 'Material de Apoio';
            case 'artigos_cientificos':
                return 'Artigos Cientifícos';
            case 'comunicados':
                return 'Comunicados';
            case 'treinamentos_e_certificacoes':
                return 'Treinamentos e Certificações';
            default:
                return '-'
        }
    }

    const handleEdit = (data: UniversityEntity) => {
        setIsEdit(true);
        setOpen(true);

        setContentName(data.name);
        setContentType(data.type_of_content);
        setContentSummary(data.resume);
        setContentLink(data.link);
        setSelectedImageFileUrl(data.fileUrlImage);
        setSelectedImagePreviewUrl(data.fileUrlImage);
        setSelectedImageBlob(data.fileUrlImage);

        setSelectedContentFileUrl(data.link);
        setSelectedContentBlob(data.link);
        setSelectedContentPreviewUrl(data.link);

        setContentId(data.id);
    }

    const mountTableBody = () => {
        universityContent.map((item: UniversityEntity) => {
            setTableBody((prev) =>
                [
                    ...prev,
                    {
                        name: item.name,
                        typeOfContent: getTypeOfContentText(item.type_of_content),
                        dateOfPublish: dayjs(item.date_of_publish).format('DD/MM/YYYY') + ' ' + dayjs(item.createdAt).format('HH:mm:ss'),
                        dateOfUpdate: dayjs(item.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
                        actions: (
                            <div>
                                <EditButton onClick={() => handleEdit(item)}/>

                                <DeleteButton id={item.id} callback={handleDelete}/>
                            </div>
                        ),
                    },
                ]
            );
        });
    };

    const handleDelete = async (id: string) => {
        try {
            UniversityApi.deleteUniversityContent(id)
                .then(response => {
                    setSnackBarSettings({
                        show: true,
                        message: 'Sucesso ao excluir conteúdo',
                        severity: 'success',
                    });
                    resetSnackBar();
                    getUniversityAllContent()
                })
                .catch((err: any) => {
                    setSnackBarSettings({
                        show: true,
                        message: err.message,
                        severity: 'error',
                    });
                    resetSnackBar();
                    setUniversityContent([]);
                })
        } catch (err: any) {
            setSnackBarSettings({
                show: true,
                message: err.message,
                severity: 'error',
            });
            resetSnackBar();
            setUniversityContent([]);
        }
    }

    const getUniversityAllContent = async () => {
        try {
            const university: UniversityEntity[] = await UniversityApi.getAllUniversityContent();

            if (university.length > 0) {
                // Ordena o array pelo campo `createdAt`
                const sortedData: UniversityEntity[] = university
                    .sort((a: UniversityEntity, b: UniversityEntity) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
                setUniversityContent(sortedData);
                setUniversityAllContent(sortedData);
            }

            if (university.length === 0) {
                setUniversityContent([]);
                setUniversityAllContent([]);
            }
        } catch (err: any) {
            handleApiError(err)
            setUniversityContent([]);
            setUniversityAllContent([]);
        }
    };

    const setSearch = (value: string): void => {
        console.log(typeof value);

        // Verifica se o valor está vazio
        if (!value?.trim()) {
            setUniversityContent(universityAllContent);
            return;
        }

        // Filtra os conteúdos
        const filteredContent: UniversityEntity[] = universityAllContent.filter((content: UniversityEntity) =>
            content.name.trim().toLowerCase().includes(value.trim().toLowerCase())
        );

        // Atualiza o conteúdo ou zero os resultados
        setUniversityContent(filteredContent.length > 0 ? filteredContent : []);
    };

    useEffect(() => {
        setTableBody([]);
        mountTableBody();
    }, [universityContent])

    useEffect(() => {
        getUniversityAllContent();
    }, []);

    return (
        <Container>
            <BaseCardBox>
                <Header>
                    <Typography variant="h3" component="h3" gutterBottom>
                        Universidade MS
                    </Typography>
                    <div>
                        <Button
                            onClick={() => {
                                setIsEdit(false);
                                setOpen(true);
                                resetFormFields();
                            }}
                            variant="contained"
                            startIcon={<AddBoxIcon/>}
                        >
                            Novo Cadastro
                        </Button>
                    </div>
                </Header>

                <BaseTextField
                    onChange={(event) => setSearch(event.target.value)}
                    label="Realizar busca"
                    variant="outlined"
                />

                <CustomizedTable tableHead={tableHead} tableBody={tableBody}/>

                <BaseDialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >

                    <form>
                        {!isEdit ? (
                            <DialogTitle id="alert-dialog-title">{'NOVO CONTEÚDO UNIVERSITÁRIO'}</DialogTitle>
                        ) : (
                            <DialogTitle id="alert-dialog-title">{'EDITAR CONTEÚDO UNIVERSITÁRIO'}</DialogTitle>
                        )}

                        {
                            loading ? (
                                <LoadingContainer>
                                    <CircularProgress color="inherit"/>
                                    <Typography variant="h5" component="h5" gutterBottom>
                                        {isEdit ? 'Editando conteúdo' : 'Salvando conteúdo'}
                                    </Typography>
                                </LoadingContainer>
                            ) : (
                                <>
                                    <TextField
                                        label="Nome do Conteúdo"
                                        variant="outlined"
                                        fullWidth
                                        margin="normal"
                                        value={contentName}
                                        required
                                        onChange={(e) => setContentName(e.target.value)}
                                    />

                                    <FormControl fullWidth margin="normal">
                                        <InputLabel>Tipo de Conteúdo</InputLabel>
                                        <Select
                                            value={contentType}
                                            required
                                            onChange={(e) => setContentType(e.target.value)}
                                            label="Tipo de Conteúdo"
                                        >
                                            <MenuItem value="material_de_apoio">Materiais de Apoio</MenuItem>
                                            <MenuItem value="artigos_cientificos">Artigos Científicos</MenuItem>
                                            <MenuItem value="treinamentos_e_certificacoes">Treinamentos e
                                                Certificações</MenuItem>
                                            <MenuItem value="comunicados">Comunicados</MenuItem>
                                        </Select>
                                    </FormControl>

                                    <TextField
                                        label="Resumo do Conteúdo"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        margin="normal"
                                        inputProps={{maxLength: 65}}
                                        helperText={`${contentSummary.length}/65`}
                                        value={contentSummary}
                                        onChange={(e) => setContentSummary(e.target.value)}
                                    />

                                    {
                                        (contentType === 'material_de_apoio' || contentType === 'artigos_cientificos' || contentType === 'comunicados') ? (
                                            <Box sx={{marginTop: '1rem', marginBottom: '1rem'}}>
                                                <Typography variant="subtitle1">Conteúdo (pdf, word,
                                                    etc...)</Typography>
                                                <input
                                                    style={{display: 'none'}}
                                                    id="upload-content"
                                                    required={contentType === 'material_de_apoio' || contentType === 'artigos_cientificos' || contentType === 'comunicados'}
                                                    type="file"
                                                    onChange={handleContentChange}
                                                />
                                                <label htmlFor="upload-content">
                                                    <Button variant="outlined" component="span"
                                                            sx={{marginTop: '0.5rem'}}>
                                                        Upload Conteúdo
                                                    </Button>
                                                </label>

                                                {
                                                    selectedContentPreviewUrl && selectedContentPreviewUrl.length > 0 ? (
                                                        <Box mt={2}>
                                                            <Typography variant="subtitle2" color={'green'}>
                                                                Conteúdo carregado!
                                                            </Typography>
                                                        </Box>
                                                    ) : (
                                                        <Box mt={2}>
                                                            <Typography variant="subtitle2" color={'red'}>Conteúdo não
                                                                carregado!</Typography>
                                                        </Box>
                                                    )
                                                }
                                            </Box>
                                        ) : (
                                            <TextField
                                                label="Link do Conteúdo"
                                                variant="outlined"
                                                fullWidth
                                                margin="normal"
                                                required
                                                value={contentLink}
                                                helperText='Cole o link do vídeo compartilhado que está no Google Drive ou no Youtube'
                                                onChange={(e) => setContentLink(e.target.value)}
                                            />
                                        )
                                    }

                                    {
                                        !isEdit && (
                                            <TextField
                                                label="Data da Publicação"
                                                type="date"
                                                variant="outlined"
                                                fullWidth={false}
                                                required
                                                margin="normal"
                                                InputLabelProps={{shrink: true}}
                                                inputProps={{
                                                    min: getYesterdayDate(), // Restringe para não aceitar datas anteriores a ontem
                                                }}
                                                value={publishDate}
                                                onChange={(e) => setPublishDate(e.target.value)}
                                            />
                                        )
                                    }

                                    <Box sx={{marginTop: '1rem'}}>
                                        <Typography variant="subtitle1">Imagem do Conteúdo</Typography>
                                        <input
                                            accept="image/*"
                                            style={{display: 'none'}}
                                            id="upload-image"
                                            required
                                            type="file"
                                            onChange={handleImageChange}
                                        />
                                        <label htmlFor="upload-image">
                                            <Button variant="outlined" component="span" sx={{marginTop: '0.5rem'}}>
                                                Upload Imagem
                                            </Button>
                                        </label>
                                        {selectedImagePreviewUrl && (
                                            <Box mt={2}>
                                                <Typography variant="subtitle2">Prévia:</Typography>
                                                <img src={selectedImagePreviewUrl} alt="Selected"
                                                     style={{width: '100%', maxHeight: '400px', objectFit: 'fill'}}/>
                                            </Box>
                                        )}
                                    </Box>

                                    <Box sx={{display: 'flex', justifyContent: 'space-between', marginTop: '2rem'}}>
                                        <Button variant="outlined" color="secondary" onClick={() => setOpen(false)}>
                                            Cancelar
                                        </Button>
                                        <Button variant="contained" color="primary" onClick={handleSave}>
                                            {isEdit ? 'Editar' : 'Salvar'}
                                        </Button>
                                    </Box>
                                </>
                            )
                        }
                    </form>

                </BaseDialog>
            </BaseCardBox>

            {
                snackBarSettings.show && (
                    <CustomizedSnackbars
                        message={snackBarSettings.message}
                        severity={snackBarSettings.severity}
                    />
                )
            }
        </Container>
    )
        ;
}
