import React, { useEffect, useState } from 'react';
import { InstrumentApi } from 'lib/api/instrument';
import {Button, CircularProgress, Pagination, Stack} from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PostAddIcon from '@mui/icons-material/PostAdd';
import CustomizedTable from 'modules/instruments/components/Table';
import DeleteButton from 'lib/components/DeleteButton';
import Container from '@mui/material/Container';
import CustomizedSnackbars from 'lib/components/SnackBar';
import Tooltip from '@mui/material/Tooltip';
import { BaseCardBox, Header, BaseTextField, BaseGetAppIcon } from './style';
import axiosInstance from 'lib/api/api';
import { AssessmentApi } from 'lib/api/assessment';
import { BaseEditIcon } from 'lib/components/EditButton/style';
import { InstrumentForm } from 'modules/instruments/components/instrument-form/instrument-form';
import {AssessmentTypeDTO, AssessmentTypeResponseDto} from 'lib/api/assessment.protocols';
import {FileApi} from "../../../../lib/api/file";
import {RoleType} from "../../../auth/common/role";
import {BaseExportExcelContainer} from "../../../sectors/pages/list/style";
import IosShareIcon from "@mui/icons-material/IosShare";
import {useSnackbar} from "notistack";
import {useUser} from "../../../auth/context/auth.consumer";

export interface IInstrument {
  id?: string;
  name: string;
  assessmentTypeId?: string;
  customLinkPath?: string;
  flagFluxAutomatic: boolean;
  customMessage?: string;
}

interface ITableBody {
  name: string;
  actions: JSX.Element;
}

interface ISnackBarSettings {
  show: boolean;
  message?: string;
  severity?: 'success' | 'error' | 'warning' | 'info';
}

const InstrumentsPage = () => {
  const [instruments, setInstruments] = useState<IInstrument[]>();
  const [search, setSearch] = useState<string>('');
  const [tableBody, setTableBody] = useState<ITableBody[]>([]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [snackBarSettings, setSnackBarSettings] = useState<ISnackBarSettings>({
    show: false,
  });
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState<number | undefined>(undefined);
  const [assessmentTypes, setAssessmentTypes] = useState<AssessmentTypeDTO[]>();
  const [currentInstrument, setCurrentInstrument] = useState<IInstrument>();
  const tableHead = ['Instrumento', 'Ações'];

  const [excelLoading, setExcelLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const user = useUser();
  
  const getInstrumentsPaginated = async () => {
    const response = await InstrumentApi.searchInstruments(search, page);
    setInstruments(response.entities);
    setPageCount(response.meta.pageCount);
  };

  const getAssessmentTypes = async () => {
    const response = await AssessmentApi.getAssessmentTypes();
    setAssessmentTypes(response);
  };

  useEffect(() => {
    getInstrumentsPaginated();
  }, [page, search]);

  useEffect(() => {
    getAssessmentTypes();
  }, []);

  const resetSnackBar = () => {
    setTimeout(() => {
      setSnackBarSettings({ show: false });
    }, 3000);
  };

  const handleCreate = async (
    instrumentName: string,
    flagFluxAutomatic: boolean,
    assessmentTypeId?: string,
    isAssessmentType?: boolean,
    assessmentTypeName?: string,
    assessmentTypeAcronym?: string,
    assessmentTypeLink?: string,
    customMessage?: string,
  ) => {
    try {
      if (flagFluxAutomatic && assessmentTypeLink && assessmentTypeLink === '') {
        setSnackBarSettings({
          show: true,
          message: 'Preencha o link externo quando a opção disparo automático estiver ativa!',
          severity: 'error',
        });
        resetSnackBar();
        return;
      }

      if (isAssessmentType) {
        if (assessmentTypeName && assessmentTypeAcronym && assessmentTypeLink && customMessage) {
          const assessmentTypeCreated: AssessmentTypeResponseDto = await AssessmentApi.createAssessmentType(assessmentTypeName, assessmentTypeAcronym);

          if (instrumentName) {
            await InstrumentApi.createInstrument({
              name: instrumentName,
              assessmentTypeId: assessmentTypeCreated.id,
              customLinkPath: assessmentTypeLink,
              customMessage,
              flagFluxAutomatic
            });
            setSnackBarSettings({
              show: true,
              message: 'Instrumento criado com sucesso!',
              severity: 'success',
            });
            resetSnackBar();
          }
        }

        getInstrumentsPaginated();
        resetSnackBar();
        return;
      }

      if (instrumentName) {
        await InstrumentApi.createInstrument({
          name: instrumentName,
          assessmentTypeId,
          customLinkPath: assessmentTypeLink,
          customMessage,
          flagFluxAutomatic
        });

        setSnackBarSettings({
          show: true,
          message: 'Instrumento criado com sucesso!',
          severity: 'success',
        });
        resetSnackBar();
      } else {
        setSnackBarSettings({
          show: true,
          message: 'Preencha o nome do instrumento',
          severity: 'error',
        });
        resetSnackBar();
      }

      getInstrumentsPaginated();
      resetSnackBar();
    } catch (err: any) {
      console.log(err)
      if (err.response.data.message === 'This instrument already exists') {
        setSnackBarSettings({
          show: true,
          message: 'Existe um instrumento cadastrado com este nome, tente novamente',
          severity: 'error',
        });
        resetSnackBar();
        return;
      }

      setSnackBarSettings({
        show: true,
        message: err.message,
        severity: 'error',
      });
      resetSnackBar();
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await InstrumentApi.deleteInstrument(id);
      setSnackBarSettings({
        show: true,
        message: 'Instrumento excluido com sucesso!',
        severity: 'success',
      });
      getInstrumentsPaginated();
      resetSnackBar();
    } catch (err: any) {
      setSnackBarSettings({
        show: true,
        message: err.response.data.message,
        severity: 'error',
      });
      resetSnackBar();
    }
  };

  const handleEdit = async (
    instrumentName: string,
    flagFluxAutomatic: boolean,
    assessmentTypeId?: string,
    isAssessmentType?: boolean,
    assessmentTypeName?: string,
    assessmentTypeAcronym?: string,
    assessmentTypeLink?: string,
    customMessage?: string,
  ) => {
    try {
      if (flagFluxAutomatic && assessmentTypeLink && assessmentTypeLink === '') {
        setSnackBarSettings({
          show: true,
          message: 'Preencha o link externo quando a opção disparo automático estiver ativa!',
          severity: 'error',
        });
        resetSnackBar();
        return;
      }

      if (currentInstrument?.id) {
        if (isAssessmentType) {
          if (assessmentTypeId && assessmentTypeName && assessmentTypeAcronym && assessmentTypeLink) {
            const assessmentTypeEdit: AssessmentTypeResponseDto = await AssessmentApi.updateAssessmentType(assessmentTypeId, assessmentTypeName, assessmentTypeAcronym);

            if (instrumentName) {
              await InstrumentApi.editInstrument(currentInstrument.id, {
                name: instrumentName,
                assessmentTypeId: assessmentTypeEdit.id,
                customLinkPath: assessmentTypeLink,
                customMessage,
                flagFluxAutomatic
              });

              setSnackBarSettings({
                show: true,
                message: 'Instrumento editado com sucesso!',
                severity: 'success',
              });
              resetSnackBar();
            }
          }

          getInstrumentsPaginated();
          resetSnackBar();
          return;
        }

        await InstrumentApi.editInstrument(currentInstrument.id, {
          name: instrumentName,
          assessmentTypeId,
          customLinkPath: assessmentTypeLink,
          customMessage,
          flagFluxAutomatic
        });
        setSnackBarSettings({
          show: true,
          message: 'Instrumento editado com sucesso!',
          severity: 'success',
        });
        resetSnackBar();
      }

      getInstrumentsPaginated();
      resetSnackBar();
    } catch (err: any) {
      if (err.response.data.message === 'This instrument already exists') {
        setSnackBarSettings({
          show: true,
          message: 'Existe um instrumento cadastrado com este nome, tente novamente',
          severity: 'error',
        });
        resetSnackBar();
        return;
      }

      setSnackBarSettings({
        show: true,
        message: err.message,
        severity: 'error',
      });
      resetSnackBar();
    }
  };

  function handleEditButton(isOpen: boolean, item: IInstrument) {
    setOpen(isOpen);
    setIsEdit(true);
    setCurrentInstrument(item);
  }

  const handleFile = async (e: any) => {
    if (e.target.files || e.target.files.length !== 0) {
      const formData = new FormData();

      formData.append('file', e.target.files[0]);

      try {
        axiosInstance.post(
          `/file-upload/upload/entity-spreadsheet?sheetEntity=INSTRUMENT`,
          formData,
        );
        setTimeout(() => {
          getInstrumentsPaginated();
        }, 500);
        setSnackBarSettings({
          show: true,
          message: 'Arquivo importado com sucesso!',
          severity: 'success',
        });
        resetSnackBar();
      } catch (err: any) {
        setSnackBarSettings({
          show: true,
          message: err.message,
          severity: 'error',
        });
        resetSnackBar();
      }
    }
  };

  const mountTableBody = () => {
    instruments?.map((item) => {
      setTableBody((prev) => [
        ...prev,
        {
          name: item.name,
          actions: (
            <div>
              <BaseEditIcon onClick={() => handleEditButton(true, item)} />
              <DeleteButton id={item.id} callback={handleDelete} />
            </div>
          ),
        },
      ]);
    });
  };

  useEffect(() => {
    setTableBody([]);
    mountTableBody();
  }, [instruments]);

  const handleCloseModalForm = () => {
    setOpen(false);
    setIsEdit(false);
  };

  const getExportExcelInstruments = async () => {
    setExcelLoading(true);
    try {
      const url = await FileApi.exportInstruments();
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', url);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      setExcelLoading(false);
      console.error(e);
      enqueueSnackbar('Erro ao exportar os instrumentos', { variant: 'error', autoHideDuration: 6000 });
    } finally {
      setExcelLoading(false);
    }
  };
  
  return (
    <Container>
      <BaseCardBox>
        <Header>
          <h3>Cadastro de Instrumentos</h3>
          <div>
            <Tooltip title="Baixar planilha de importação">
              <a href="https://msbackend.blob.core.windows.net/planilha-entidade/INSTRUMENTOS.xlsx">
                <BaseGetAppIcon />
              </a>
            </Tooltip>

            <input id="upload" type="file" onChange={(e) => handleFile(e)} />
            <label htmlFor="upload">
              <PostAddIcon />
              Importar
            </label>

            <Button
              onClick={() => {
                setIsEdit(false);
                setOpen(true);
              }}
              variant="contained"
              startIcon={<AddBoxIcon />}
            >
              Novo Cadastro
            </Button>
          </div>
        </Header>

        <InstrumentForm
          open={open}
          handleCloseModalForm={handleCloseModalForm}
          isEdit={isEdit}
          assessmentTypes={assessmentTypes ? assessmentTypes : []}
          currentInstrument={currentInstrument}
          handleCreate={handleCreate}
          handleEdit={handleEdit}/>

        <BaseTextField
          onChange={(event) => {
            page !== 1 && setPage(1);
            setSearch(event.target.value);
          }}
          label="Realizar busca"
          variant="outlined"
        />
      </BaseCardBox>

      {
          user && user.role === RoleType.ADMIN && (
              <BaseExportExcelContainer>
                <div>
                  <Tooltip title="Exportar em excel todos os registros de instrumentos">
                    <Button
                        variant="contained"
                        startIcon={
                          excelLoading ? (
                              <CircularProgress color="inherit" size={20} />
                          ) : (
                              <IosShareIcon />
                          )
                        }
                        onClick={() => getExportExcelInstruments()}
                        disabled={excelLoading}
                    >
                      Exportar em excel
                    </Button>
                  </Tooltip>
                </div>
              </BaseExportExcelContainer>
          )
      }
      
      <CustomizedTable tableHead={tableHead} tableBody={tableBody} />

      {snackBarSettings.show && (
        <CustomizedSnackbars
          message={snackBarSettings.message}
          severity={snackBarSettings.severity}
        />
      )}
      <Stack spacing={2} alignItems={'center'}>
        <Pagination
          count={pageCount}
          page={page}
          onChange={(e, value) => setPage(value)}
          defaultPage={page}
        />
      </Stack>
    </Container>
  );
};

export { InstrumentsPage };
