import {
    DialogTitle,
    FormControl,
    InputLabel,
    OutlinedInput,
    MenuItem,
    DialogActions,
    Button,
    TextField,
    Select, Box, Typography, Switch, FormControlLabel,
} from '@mui/material';

import {AssessmentTypeDTO} from 'lib/api/assessment.protocols';
import {FieldRow} from 'modules/analyst-reports/style';
import {BaseDialog} from 'modules/companies/pages/list/style';
import {IInstrument} from 'modules/instruments/pages/list';
import React, {useEffect, useState} from 'react';
import {Label} from "@mui/icons-material";

interface FormProps {
    open: boolean;
    handleCloseModalForm: () => void;
    isEdit: boolean;
    assessmentTypes: AssessmentTypeDTO[];
    currentInstrument: IInstrument | undefined;
    handleCreate: (
        instrumentName: string,
        flagFluxAutomatic: boolean,
        assessmentTypeId?: string,
        isAssessmentType?: boolean,
        assessmentTypeName?: string,
        assessmentTypeAcronym?: string,
        assessmentTypeLink?: string,
        customMessage?: string,
    ) => void;
    handleEdit: (
        instrumentName: string,
        flagFluxAutomatic: boolean,
        assessmentTypeId?: string,
        isAssessmentType?: boolean,
        assessmentTypeName?: string,
        assessmentTypeAcronym?: string,
        assessmentTypeLink?: string,
        customMessage?: string,
    ) => void;
}

export const InstrumentForm = ({
                                   open,
                                   handleCloseModalForm,
                                   handleCreate,
                                   handleEdit,
                                   isEdit,
                                   assessmentTypes,
                                   currentInstrument,
                               }: FormProps) => {
    const [instrumentName, setInstrumentName] = useState<string>('');
    const [assessmentTypeId, setAssessmentTypeId] = useState<string>('');

    const [isAssessmentType, setIsAssessmentType] = useState(false)
    const [newTypeName, setNewTypeName] = useState('')
    const [newTypeAcronym, setNewTypeAcronym] = useState('')
    const [newTypeLink, setNewTypeLink] = useState('')
    const [customMessage, setCustomMessage] = useState('')
    const [flagFluxAutomatic, setFlagFluxAutomatic] = useState(false)

    useEffect(() => {
        if (isEdit) {
            if (currentInstrument) {
                setInstrumentName(currentInstrument?.name);
                currentInstrument.assessmentTypeId &&
                setAssessmentTypeId(currentInstrument?.assessmentTypeId);

                const assessmentTypeFilter: AssessmentTypeDTO | undefined= assessmentTypes.find((item: AssessmentTypeDTO) => item.id === currentInstrument.assessmentTypeId);

                if (assessmentTypeFilter) {
                    setNewTypeName(assessmentTypeFilter.name)
                    setNewTypeAcronym(assessmentTypeFilter.acronym)
                }

                setNewTypeLink(currentInstrument && currentInstrument.customLinkPath ? currentInstrument.customLinkPath : '')
                setIsAssessmentType(!!(currentInstrument && currentInstrument.customLinkPath && currentInstrument.customLinkPath.length > 0))

                setCustomMessage(currentInstrument && currentInstrument.customMessage ? currentInstrument.customMessage : '')
                setFlagFluxAutomatic(currentInstrument && currentInstrument.flagFluxAutomatic)
            }
        } else {
            setInstrumentName('');
            setAssessmentTypeId('');
            setIsAssessmentType(false);
            setNewTypeName('');
            setNewTypeAcronym('');
            setNewTypeLink('');
            setCustomMessage('')
            setFlagFluxAutomatic(false)
        }
    }, [isEdit]);

    const handleSaveInstrumentModal = (e: React.FormEvent) => {
        e.preventDefault();
        cleanForm();
        if (isEdit) {
            handleEdit(instrumentName, flagFluxAutomatic, assessmentTypeId, isAssessmentType, newTypeName, newTypeAcronym, newTypeLink, customMessage);
            return;
        } else {
            handleCreate(instrumentName, flagFluxAutomatic, assessmentTypeId, isAssessmentType, newTypeName, newTypeAcronym, newTypeLink, customMessage);
        }
    };

    const cleanForm = () => {
        handleCloseModalForm();
        setInstrumentName('');
        setAssessmentTypeId('');
        setIsAssessmentType(false)
        setNewTypeName('')
        setNewTypeAcronym('')
        setNewTypeLink('')
        setCustomMessage('')
        setFlagFluxAutomatic(false)
    };

    return (
        <BaseDialog
            open={open}
            onClose={cleanForm}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <form onSubmit={handleSaveInstrumentModal}>
                <DialogTitle id="alert-dialog-title">
                    {isEdit ? 'EDITAR INSTRUMENTO' : 'NOVO INSTRUMENTO'}
                </DialogTitle>

                <Box sx={{p: 2}}>
                    <TextField
                        required
                        fullWidth
                        margin="normal"
                        onChange={(event) => setInstrumentName(event.target.value)}
                        label="Nome do instrumento"
                        variant="outlined"
                        value={instrumentName}
                    />

                    <Box sx={{mt: 2, mb: 2}}>
                        <Typography component="div">
                            <Box sx={{display: 'flex', alignItems: 'center'}}>
                                <Switch
                                    checked={isAssessmentType}
                                    onChange={(event) => setIsAssessmentType(event.target.checked)}
                                />
                                <span>{isEdit ? 'Editar o tipo de prova' : 'Criar novo tipo de prova'}</span>
                            </Box>
                        </Typography>
                    </Box>

                    {isAssessmentType ? (
                        <>
                            <TextField
                                required
                                fullWidth
                                margin="normal"
                                onChange={(event) => setNewTypeName(event.target.value)}
                                label="Nome do novo tipo de prova"
                                variant="outlined"
                                value={newTypeName}
                            />
                            <TextField
                                required
                                fullWidth
                                margin="normal"
                                onChange={(event) => setNewTypeAcronym(event.target.value)}
                                label="Acrônimo"
                                variant="outlined"
                                value={newTypeAcronym}
                            />

                            <Typography component='label'>Exemplo: <b>PA-CON</b> (Prova de Atenção Concentrada)</Typography>
                        </>
                    ) : (
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="assessment-type-label">Tipo de prova</InputLabel>
                            <Select
                                labelId="assessment-type-label"
                                id="assessment-type-select"
                                value={assessmentTypeId}
                                onChange={(event) => setAssessmentTypeId(event.target.value as string)}
                                input={<OutlinedInput label="Tipo de prova"/>}
                            >
                                {assessmentTypes?.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                                <MenuItem value="">Não se aplica</MenuItem>
                            </Select>
                        </FormControl>
                    )}

                    <TextField
                        required={flagFluxAutomatic}
                        fullWidth
                        margin="normal"
                        onChange={(event) => setNewTypeLink(event.target.value)}
                        label="Link da prova externa"
                        variant="outlined"
                        value={newTypeLink}
                    />

                    <TextField
                        fullWidth
                        margin="normal"
                        onChange={(event) => setCustomMessage(event.target.value)}
                        label="Mensagem personalizada"
                        variant="outlined"
                        value={customMessage}
                        multiline
                        rows={4}
                    />

                    <FormControlLabel
                        control={
                            <Switch
                                checked={flagFluxAutomatic}
                                onChange={(event) => setFlagFluxAutomatic(event.target.checked)}
                                color="primary"
                            />
                        }
                        label="Disparo automático para o candidato"
                    />
                </Box>

                <DialogActions>
                    <Button variant="outlined" color="error" onClick={cleanForm}>
                        Cancelar
                    </Button>
                    <Button variant="contained" color="success" type="submit" autoFocus>
                        Salvar
                    </Button>
                </DialogActions>
            </form>
        </BaseDialog>
    );
};
